import React from 'react'
import './index.css'
import Cfglogo from '../../images/Cfglogo.svg'
import Enixerlogo from '../../images/Enixerlogo.svg'
import Hubberlogo from '../../images/Hubberlogo.svg'
import Innohublogo from '../../images/Innohublogo.svg'
import Onetoonelogo from '../../images/Onetoonelogo.svg'
import Psdlogo from '../../images/Psdlogo.svg'
import Sixlogo from '../../images/Sixlogo.svg'
import Xerxialogo from '../../images/Xerxialogo.svg'
import Line from '../../images/Line.svg'
import Discord from '../../images/Discord.svg'
import Telegram from '../../images/Telegram.svg'
import Twitter from '../../images/Twitter.svg'
import Tele1 from '../../images/Tele1.png'

export default function index() {

    const openTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <div className="investorscontent">
            <div className="investorstitle">Our Investors & Partners</div>

            <div className="investorslogo">
                <div className="logoline">
                    <img id="logosize" src={Xerxialogo}></img>
                    <img id="logosize" src={Innohublogo}></img>
                    <img id="logosize" src={Hubberlogo}></img>
                    <img id="logosize" src={Onetoonelogo}></img>
                </div>
                <div className="logoline">
                    <img id="logosize" src={Enixerlogo}></img>
                    <img id="logosize" src={Psdlogo}></img>
                    <img id="logosize" src={Sixlogo}></img>
                    <img id="logosize" src={Cfglogo}></img>
                </div>

                <div className="socialbox">
                    <div className="textsocial">JOIN US</div>
                    <img id="sociallogo" className='cursor' onClick={() => openTab('https://bit.ly/3zrPIBf')} src={Line}></img>
                    <img id="sociallogo" className='cursor' onClick={() => openTab('https://t.me/Z7DAOGLOBAL')} src={Telegram}></img>
                    <img id="sociallogo" className='cursor' onClick={() => openTab('https://twitter.com/Z7DAOofficial')} src={Twitter}></img>
                    <img id="sociallogo" className='cursor' onClick={() => openTab('https://discord.gg/z7dao')} src={Discord}></img>
                    {/* <img id="sociallogo" src={Line}></img>
                    <img id="sociallogo" src={Telegram}></img>
                    <img id="sociallogo" src={Twitter}></img>
                    <img id="sociallogo" src={Discord}></img> */}
                </div>
            </div>

        </div>
    )
}
