import React from 'react'
import './index.css'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import User from '../../images/User.svg'
import User2 from '../../images/User2.svg'

export default function index() {
    return (
        <div>
            <div className='div-sub-title-none'>
                <Box sx={{ width: '100%' }} mt={6} >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div>
                                <div className="gamercontent">
                                    <img className="user" src={User}></img>
                                    <div className="topic">3.1B</div>
                                    <div className="plus">+</div>
                                </div>
                                <div className="textgamer">Gamers | Across The World</div>
                            </div>

                            <div>
                                <div className="gamingcontent">
                                    <div className="topic">$200B</div>
                                    <div className="plus">+</div>
                                </div>
                                <div className="textgaming">Gaming | Market Valued</div>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="lagertext">
                                <div id="text">The First Decentralized</div>
                                <div id="text">Esports Teams Platform</div>
                                <div id="text">Is Being Built Here</div>
                            </div>
                            <div className="smalltext">
                                <div id="small2">Fans right to vote.</div>
                                <div id="small2">Players incentives.</div>
                                <div id="small2">Invest with ease.</div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className='paragraph2'>
                <div className='paragraph2-left'>
                    <div className='text-left-sub-title'>
                        The first decentralized
                        esports teams platform
                        is being built here
                    </div>
                    <div className='text-left-sub-title2'>
                        Fans right to vote.
                        Players incentives.
                        Invest with ease.
                    </div>
                </div>
                <div className='paragraph2-right'>
                    <div className='div-game-paragraph2'>
                        <div className="gamercontent">
                            <img src={User2}></img>
                            <div className="topic2">3.1B</div>
                            <div className="plus2">+</div>
                        </div>
                        <div className="textgamer">Gamers | Across The World</div>
                        <div className="gamercontent">
                            <div className="topic2">$200B</div>
                            <div className="plus2">+</div>
                        </div>
                        <div className="textgamer">Gaming | Market Valued</div>
                    </div>
                </div>
            </div>

            <div className='paragraph3'>
                <div className='paragraph3-right'>
                    <div className='div-game-paragraph2'>
                        <div className="gamercontent">
                            <img src={User2}></img>
                            <div className="topic2">3.1B</div>
                            <div className="plus2">+</div>
                        </div>
                        <div className="textgamer">Gamers | Across The World</div>
                        <div className="gamercontent">
                            <div className="topic2">$200B</div>
                            <div className="plus2">+</div>
                        </div>
                        <div className="textgamer">Gaming | Market Valued</div>
                    </div>
                </div>

                <div className='paragraph3-left'>
                    <div className='text-left-sub-title-tablet'>
                        The first decentralized<br />
                        esports teams platform<br />
                        is being built here<br />
                    </div>
                    <div className='text-left-sub-title-tablet2'>
                        Fans right to vote.<br />
                        Players incentives.<br />
                        Invest with ease.
                    </div>
                </div>
            </div>

        </div>
    )
}
