import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ROUTE_PATH } from '../constants/paths'
import HomePage from '../pages/Home'

export default function AppRouter() {

    return (
        <div>
            <Router>
                <Routes>
                    <Route path={ROUTE_PATH.HOME} element={<HomePage />} />
                </Routes>
            </Router>
        </div>
    )
}