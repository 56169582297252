import React from 'react'
import './index.css'


export default function index() {
    return (
        <div className="subvaluecontent">
            <div className="largetaxtvalue">
                <div>Z7 ESPORTS INVESTMENT PLATFORM</div>
                <div>AND LAUNCHPAD</div>
            </div>

            <div className="bluetextvalue">Own Z7, Own esports teams</div>

            <div className="smalltextvalue">
                Investment in Esports teams made easy on our platform
                by participating in our Esports teams launchpad, proposing new ideas,
                voting for our teams new direction and earning from the teams’ performance
            </div>
        </div>
    )
}
