import React from 'react'
import './index.css'


export default function index() {
    return (
        <div className="contentwcam">

            <div id="textwcam">Watch, Cheer And Manage Your</div>
            <div id="textwcam">Own Esports Teams.</div>

            <div className="text2box">
                <div id="text2">The future of gaming and Epsorts industry</div>
                <div id="text2">•</div>
                <div id="text2">Powered by Z7DAO ecosystem</div>
            </div>

        </div>
    )
}
