import React from 'react'
import './BackgroundFeature.css'
import Feature from '../components/Feature'

export default function BackgroundFeature() {
    return (
        <div className="container-feature">
            <div className='background-feature'>
                <div className='centered'>
                    <Feature />
                </div>
            </div>
        </div>
    )
}
