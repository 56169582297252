import React, { useState } from 'react'
import background4 from '../../asset/images/background4.png'
import thieves from '../../asset/images/100THIEVES.png'
import cloud9 from '../../asset/images/Cloud9.png'
import fazeclan from '../../asset/images/Fazeclan.png'
import teamliquid from '../../asset/images/Teamliquid.png'
import tsm from '../../asset/images/TSM.png'
import z7 from '../../asset/images/Z7.png'
import './valuable.css'
import Grid from '@mui/material/Grid'
import SubTitleValue from '../../components/SubTitleValue'

export default function Valuable() {

    const [header, setHeader] = useState(['Most Valuable Esports Teams', 'Value', 'Growth', 'EST.Revenue', 'Esports Revenue'])
    const [body, setBody] = useState([[tsm, 'TSM', '$540M', '32%', '$45M', '50%'], [thieves, 'TSM2', '$540M2', '32%2', '$45M2', '50%2']])

    const [btn1, setBtn1] = useState(true)
    const [btn2, setBtn2] = useState(false)
    const [btn3, setBtn3] = useState(false)
    const [btn4, setBtn4] = useState(false)


    return (
        <>
            <div className='componentValue' >
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div className='divTable'>
                        <Grid container className='table' >
                            <Grid item xs={9}  >
                                <div className='divCol'>
                                    <div className='col3'>Most Valuable Esports Teams</div>
                                    <div className='col2'>Value</div>
                                    <div className='col2'>Growth</div>
                                    <div className='col2'>EST.Revenue</div>
                                    <div className='col2'>Esports Revenue</div>
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbodyTop' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={tsm} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>TSM</div>
                                    </div>
                                    <div className='colText2'  >$540M</div>
                                    <div className='colText2'  >32%</div>
                                    <div className='colText2'  >$45M</div>
                                    <div className='colText2'  >50%</div>
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={thieves} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>100 THIEVES</div>
                                    </div>
                                    <div className='colText2'  >$460M</div>
                                    <div className='colText2'  >142%</div>
                                    <div className='colText2'  >$30M</div>
                                    <div className='colText2'  >70%</div>
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={teamliquid} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>Team Liquid</div>
                                    </div>
                                    <div className='colText2'  >$440M</div>
                                    <div className='colText2'  >42%</div>
                                    <div className='colText2'  >$28M</div>
                                    <div className='colText2'  >89%</div>
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={fazeclan} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>FAZE CLAN</div>
                                    </div>
                                    <div className='colText2'  >$400M</div>
                                    <div className='colText2'  >31%</div>
                                    <div className='colText2'  >$40M</div>
                                    <div className='colText2'  >20%</div>
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={cloud9} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>CLOUD 9</div>
                                    </div>
                                    <div className='colText2'  >$380M</div>
                                    <div className='colText2'  >9%</div>
                                    <div className='colText2'  >$16M</div>
                                    <div className='colText2'  >35%</div>
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbodyBottom' >
                                <div className='divRowBottom'>
                                    <div className='col1'>
                                        <img src={z7} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>Z7</div>
                                    </div>
                                    <div className='colText2'  >$100k</div>
                                    <div className='colText2'  >xx%</div>
                                    <div className='colText2'  >$xxM</div>
                                    <div className='colText2'  >xx%</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='divTableTablet'>
                        <Grid container className='table' >
                            <Grid item xs={9}  >
                                <div className='divCol'>
                                    <div className='col3' >Most Valuable Esports Teams</div>
                                </div>
                                <div className='divCol'>
                                    <div className={btn1 ? 'col2Selected' : 'col2'}
                                        onClick={() => {
                                            setBtn1(true)
                                            setBtn2(false)
                                            setBtn3(false)
                                            setBtn4(false)
                                        }} >Value</div>
                                    <div className={btn2 ? 'col2Selected' : 'col2'}
                                        onClick={() => {
                                            setBtn1(false)
                                            setBtn2(true)
                                            setBtn3(false)
                                            setBtn4(false)
                                        }}>Growth</div>
                                    <div className={btn3 ? 'col2Selected' : 'col2'}
                                        onClick={() => {
                                            setBtn1(false)
                                            setBtn2(false)
                                            setBtn3(true)
                                            setBtn4(false)
                                        }}>EST.Revenue</div>
                                    <div className={btn4 ? 'col2Selected' : 'col2'}
                                        onClick={() => {
                                            setBtn1(false)
                                            setBtn2(false)
                                            setBtn3(false)
                                            setBtn4(true)
                                        }}>Esports Revenue</div>
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbodyTop' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={tsm} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>TSM</div>
                                    </div>
                                    {btn1 ? <div className='colText2'>$540M</div> : null}
                                    {btn2 ? <div className='colText2'>32%</div> : null}
                                    {btn3 ? <div className='colText2'>$45M</div> : null}
                                    {btn4 ? <div className='colText2'>50%</div> : null}
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={thieves} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>100 THIEVES</div>
                                    </div>
                                    {btn1 ? <div className='colText2'>$460M</div> : null}
                                    {btn2 ? <div className='colText2'>142%</div> : null}
                                    {btn3 ? <div className='colText2'>$30M</div> : null}
                                    {btn4 ? <div className='colText2'>70%</div> : null}
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={teamliquid} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>Team Liquid</div>
                                    </div>
                                    {btn1 ? <div className='colText2'>$440M</div> : null}
                                    {btn2 ? <div className='colText2'>42%</div> : null}
                                    {btn3 ? <div className='colText2'>$28M</div> : null}
                                    {btn4 ? <div className='colText2'>89%</div> : null}
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={fazeclan} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>FAZE CLAN</div>
                                    </div>
                                    {btn1 ? <div className='colText2'>$400M</div> : null}
                                    {btn2 ? <div className='colText2'>31%</div> : null}
                                    {btn3 ? <div className='colText2'>$40M</div> : null}
                                    {btn4 ? <div className='colText2'>20%</div> : null}
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbody' >
                                <div className='divRow'>
                                    <div className='col1'>
                                        <img src={cloud9} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>CLOUD 9</div>
                                    </div>
                                    {btn1 ? <div className='colText2'>$380M</div> : null}
                                    {btn2 ? <div className='colText2'>9%</div> : null}
                                    {btn3 ? <div className='colText2'>$16M</div> : null}
                                    {btn4 ? <div className='colText2'>35%</div> : null}
                                </div>
                            </Grid>

                            <Grid item xs={9} className='tbodyBottom' >
                                <div className='divRowBottom'>
                                    <div className='col1'>
                                        <img src={z7} style={{ marginLeft: '10%' }} />
                                        <div className='titleTeamm'>Z7</div>
                                    </div>
                                    {btn1 ? <div className='colText2'>$100k</div> : null}
                                    {btn2 ? <div className='colText2'>xx%</div> : null}
                                    {btn3 ? <div className='colText2'>$xxM</div> : null}
                                    {btn4 ? <div className='colText2'>xx%</div> : null}
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <SubTitleValue />
                </div>
            </div>
        </>
    )

}