import React from 'react'
import './index.css'
import Watch from '../../images/Watch.png'
import Vote from '../../images/Vote.png'
import Earn from '../../images/Earn.png'
import Watch1 from '../../images/Watch1.png'
import Vote1 from '../../images/Vote1.png'
import Earn1 from '../../images/Earn1.png'

export default function index() {
    return (
        <>
            <div className='div-first'>
                <div className='div-title-head' >
                    <div className='text-the-first'>
                        The First
                        Decentralized
                        ESports Teams
                        Platform.
                    </div>
                </div>
                <div className='div-sub-title' >
                    <div className='text-the-first-sub'>
                        Esports teams built by the community,
                        owned by the community
                    </div>
                </div>

            </div>
            <div className='div-watch-vote-earn'>
                <div className='group'>
                    <div className='icon-watch'>
                        <img className='image-watch-png' src={Watch} />
                    </div>
                    <div className='icon-vote'>
                        <img className='image-vote-png' src={Vote} />
                    </div>
                    <div className='icon-earn'>
                        <img className='image-earn-png' src={Earn} />
                    </div>
                </div>
            </div>
            <div className='div-watch-vote-earn1'>
                <div className='group'>
                    <div className='icon-watch'>
                        {/* <img src={Watch1} /> */}
                        <img className='image-watch-png' src={Watch1} />
                    </div>
                    <div className='icon-vote'>
                        {/* <img src={Vote1} /> */}
                        <img className='image-vote-png' src={Vote1} />
                    </div>
                    <div className='icon-earn'>
                        {/* <img src={Earn1} /> */}
                        <img className='image-earn-png' src={Earn1} />
                    </div>
                </div>
            </div>

        </>
    )
}
