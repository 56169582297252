import React, { useEffect, useState } from 'react'
import './index.css'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import logo from '../../images/Logo.png'
import Vector from '../../images/Vector.svg'
import Button from '@mui/material/Button'
import Pdf from '../../document/Z7dao.pdf'
import { useNavigate } from 'react-router-dom'

export default function Navbar() {
    const navigate = useNavigate()
    const [toggleMenu, setToggleMenu] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {

        const changeWidth = () => {
            // console.log('window.innerWidth >>>' + screenWidth)
            setToggleMenu(false)
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }

    }, [])

    const openTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            {toggleMenu && (
                <div className='div-paper' >
                    <div style={{ marginTop: 90 }}>
                        <li className="items_navbar container_Hamburger" onClick={() => openTab('https://pancakeswap.finance/swap?inputCurrency=0x19e3cad0891595d27a501301a075eb680a4348b6')}>Purchase</li>
                        <li className="items_navbar1 container_Hamburger ">
                            Vote
                            <div className="text_coming_hamburger">COMING SOON</div>
                        </li>
                        <li className="items_navbar container_Hamburger" >
                            <div onClick={() => { openTab(Pdf) }} className="button-lite1"></div>
                        </li>
                        <li className="items_navbar1 container_Hamburger" onClick={() => openTab('https://token.z7dao.com/')}>
                            <div className="button-launch1" ><div className='text-launch'>Launch App</div></div>
                            {/* <div className="text_coming_hamburger">COMING SOON</div> */}
                        </li>
                    </div>
                </div>
            )}
            <div className='div_navbar' >
                <Toolbar >
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                            <img src={logo} style={{ width: 45 }} />
                            <div className='text-z7'>Z7DAO</div>
                        </div>
                    </Typography>
                    <div className='text-purchase' onClick={() => openTab('https://pancakeswap.finance/swap?inputCurrency=0x19e3cad0891595d27a501301a075eb680a4348b6')}>Purchase</div>
                    <div className='div-vesting'>
                        <div className='text-vote'>Vote</div>
                        <div className="text-color-white text-size-coming">COMING SOON</div>
                    </div>
                    <div onClick={() => { openTab(Pdf) }} className="button-lite"></div>
                    <div className="button-launch" onClick={() => openTab('https://token.z7dao.com/')}><div className='text-launch'>Launch App</div></div>
                    {/* <div className='div-coming' onClick={() => openTab('https://z7dao.ml/')}>
                        <div className="text-color-white text-size-coming">COMING SOON</div>
                    </div> */}
                    <div className="container_Hamburger"
                        onClick={() => setToggleMenu(!toggleMenu)}>
                        <img src={Vector} style={{ width: 30, zIndex: 10000, position: 'relative' }} />
                    </div>
                </Toolbar >
            </div >

        </>
    )
}
