import './App.css'
import AppRouter from './navigation/AppRouter'

function App() {
  return (
    <AppRouter />
  )
}

export default App
