import React from 'react'
import './index.css'
import PurButton from '../../images/PurButton.svg'
import z7coin01 from '../../images/z7coin-01.png'
import z7coin02 from '../../images/z7coin-02.png'
import z7coin03 from '../../images/z7coin-03.png'


export default function index() {

    const openTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <div className="utilitytokencontent">
            <div className="cardtoken">
                <div className="cardtitle">Utility Token</div>
                <div className="cardline"></div>
                <div className="cardcontent">
                    <div className="textgroup">
                        Earn rewards from our teams performance
                        and our platform competitions
                    </div>
                    <div>Invest in new DAO Esports teams</div>
                    <div>Other special offers from your team</div>
                    <div>Governance team's direction</div>
                    <div>Exclusive Merchandise</div>
                    {/* <img className="purbutton" src={PurButton}></img> */}
                    <div className='cardBtn'>
                    <div onClick={() => openTab('https://pancakeswap.finance/swap?inputCurrency=0x19e3cad0891595d27a501301a075eb680a4348b6')} className="purbutton"></div>
                    <div onClick={() => openTab('https://www.dextools.io/app/bnb/pair-explorer/0x9270f977128ab37c923a04572b4053549cebbd24')} className="livebutton"></div>
                    </div>
                </div>
            </div>

            <div className="tokenarea">
                <img className="token1" src={z7coin01}></img>
                <img className="token2" src={z7coin02}></img>
                <img className="token3" src={z7coin03}></img>
            </div>

            <img className="token1m" src={z7coin01}></img>
            <img className="token2m" src={z7coin02}></img>
            <img className="token3m" src={z7coin03}></img>

            <img className="tokentablet1" src={z7coin01}></img>
            <img className="tokentablet2" src={z7coin02}></img>
            <img className="tokentablet3" src={z7coin03}></img>

        </div>
    )
}
