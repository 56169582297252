import React from 'react'
import './BackgroundFirst.css'
import Navbar from '../components/Navbar'
import TheFirstDecentralized from '../components/TheFirstDecentralized'
import SubTitleDecentralized from '../components/SubTitleDecentralized'

export default function BackgroundFirst() {
    return (
        <>
            <div className='background-first'>
                <Navbar />
                <TheFirstDecentralized />
                <SubTitleDecentralized />
            </div>
            <div className='background-first2'>
                <Navbar />
                <TheFirstDecentralized />
                <SubTitleDecentralized />
            </div>
        </>
    )
}
