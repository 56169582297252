import React from 'react'
import './index.css'
import Challenge from '../../images/Challenge.svg'
import Tournament from '../../images/Tournament.svg'
import Watchplayearn from '../../images/Watchplayearn.svg'
import Game from '../../images/Game.svg'
import Steam from '../../images/steam.svg'
import Nft from '../../images/nft.svg'
import Integrate from '../../images/integrate.svg'
import TeamEsport from '../../images/TeamEsport.svg'

export default function index() {
    return (
        <div className='div-feature'>
            <div className='parent'>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={Challenge} />
                    </div>
                    <div className='text-feature'>
                        Challenge
                    </div>
                </div>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={Tournament} />
                    </div>
                    <div className='text-feature'>
                        Tournament
                    </div>
                </div>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={Watchplayearn} />
                    </div>
                    <div className='text-feature'>
                        Watch, Play,
                        Vote and Earn
                    </div>
                </div>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={Game} />
                    </div>
                    <div className='text-feature'>
                        Gaming
                        Launchpad
                    </div>
                </div>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={Steam} />
                    </div>
                    <div className='text-feature'>
                        Stream To Earn
                    </div>
                </div>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={Nft} />
                    </div>
                    <div className='text-feature'>
                        On-Chain
                        NFT Marketplace
                    </div>
                </div>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={Integrate} />
                    </div>
                    <div className='text-feature'>
                        Integration
                        with Hubber
                        ecosystem
                    </div>
                </div>
                <div>
                    <div className='frame-feature'>
                        <img className='icon-feature' src={TeamEsport} />
                    </div>
                    <div className='text-feature'>
                        Esports Teams Launchpad
                    </div>
                </div>

            </div>
        </div>
    )
}