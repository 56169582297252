import React from 'react'
import BackgroundFirst from '../../components/BackgroundFirst'
import Navbar from '../../components/Navbar'
import SubTitleDecentralized from '../../components/SubTitleDecentralized'
import WatchCheerAndManage from '../../components/WatchCheerAndManage'
import TheFirstDecentralized from '../../components/TheFirstDecentralized'
import SubTitleValue from '../../components/SubTitleValue'
import UtilityToken from '../../components/UtilityToken'
import Investors from '../../components/Investor'
import Valuable from '../../components/Valuable'
import './home.css'
import BackgroundFeature from '../../components/BackgroundFeature'
import background5 from '../../asset/images/background5.png'
export default function HomePage() {
    return (
        <div style={{ backgroundColor: 'black' }}>
            <BackgroundFirst />
            <Valuable />
            {/* <SubTitleValue /> */}
            {/* <SubTitleDecentralized /> */}

            <div >
                <BackgroundFeature />
                <img className='background5' src={background5} />
                <div className='background5Mobile' />
                <WatchCheerAndManage />
            </div>
            <div className='background1'>
                <UtilityToken />
                <Investors />
            </div>

        </div>
    )

}
